import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import '../../styles/DeleteModal.css'

const ActivateDoctorConfirmationModal = ({ show, onHide, onConfirm ,selectedRow}) => {
  return (

    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton className='bg-gray-800 text-white modal-header'>
        <Modal.Title className='text-white'>Confirm Activate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to {selectedRow?.status ? "Deactivate" : "Activate"} this item?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirm}>
        {selectedRow?.status ? "Deactivate" : "Activate"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ActivateDoctorConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ActivateDoctorConfirmationModal;
