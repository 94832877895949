import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  fetchData,
  deleteData,
  handlePageChange,
  getCurrentData,
} from "../../utils/utils";
import useModal from "../../hooks/useModal";
import Pagination from "../../components/commonComponent/Pagination";
import DeleteConfirmationModal from "../../components/commonComponent/DeleteConfirmationModal";
import ActivateDoctorConfirmationModal from "../../components/commonComponent/activateTherapist";

import Table from "../../components/commonComponent/Table";
import ViewModal from "../../components/commonComponent/ViewModal";
import Search from "../../components/commonComponent/Search";
import Filter from "../../components/commonComponent/Filter";
import { toast } from "react-toastify";

const API_URL = process.env.REACT_APP_API_URL;

const columns = [
  { header: "First Name", accessor: "firstName" },
  { header: "Last Name", accessor: "lastName" },
  { header: "Provider Type", accessor: "providerType" },
  { header: "State", accessor: "state" },
  { header: "City", accessor: "city" },
  { header: "Specialities", accessor: "specialities" },
];

const ITEMS_PER_PAGE = 10;

const Therapist = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [activationItem, setActivationItem] = useState(null); // State for activation
  const [selectedRow, setSelectedRow] = useState(null); // State for row data

  const {
    showDeleteModal,
    showActivateModal,
    showViewModal,
    selectedItem,
    handleOpenDeleteModal,
    handleCloseDeleteModal,
    handleOpenActivateModal,
    handleCloseActivateModal,
    handleOpenViewModal,
    handleCloseViewModal,
  } = useModal();
  const [error, setError] = useState(null);
  
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(
      `${API_URL}/getAlltherapistList`,
      (result) => {
        setData(result);
        setFilteredData(result);
        setLoading(false);
      },
      setError
    );
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get("page");
    if (page) {
      setCurrentPage(Number(page));
    }
  }, [location]);
  // Handle search
  const handleSearch = (searchTerm) => {
    const filtered = data.filter((therapist) => {
      const firstName = therapist.firstName || "";
      const lastName = therapist.lastName || "";
      const email = therapist.email || "";
      const providerType = therapist.providerType || "";


      return (
        firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        providerType.toLowerCase().includes(searchTerm.toLowerCase()) ||
        email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handleFilterChange = (selectedFilters) => {
    setFilters(selectedFilters);

    const { State = [], City = [], Specialities = [] } = selectedFilters;

    const filtered = data.filter((therapist) => {
      const stateMatch = State.length === 0 || State.includes(therapist.state);
      const cityMatch = City.length === 0 || City.includes(therapist.city);
      const specialitiesMatch =
        Specialities.length === 0 ||
        Specialities.includes(therapist.specialities);
      return stateMatch && cityMatch && specialitiesMatch;
    });

    setFilteredData(filtered);
    setCurrentPage(1);
  };

  

  const handleActivateConfirm = () => {
    console.log('okkkkkkkkkkkkkkkkkk',selectedItem,selectedRow)
    if (selectedRow) {
      const payload = {
        userId: selectedRow._id,
        status: !selectedRow.status, // Toggle the status
      };
  
      fetch(`${API_URL}/activateDoctor`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload), // Send the updated status
      })
        .then((response) => {
          console.log(response,'isduisduisd')
          if (!response.ok) {
            throw new Error("Failed to update status");
          }
          return response.json();
        })
        .then(() => {
          // Update local data with the new status
          setData((prevData) =>
            prevData.map((item) =>
              item._id === selectedRow._id
                ? { ...item, status: !selectedRow.status }
                : item
            )
          );
          setFilteredData((prevFilteredData) =>
            prevFilteredData.map((item) =>
              item._id === selectedRow._id
                ? { ...item, status: !selectedRow.status }
                : item
            )
          );
  
          const message = selectedRow.status
            ? "Doctor deactivated successfully"
            : "Doctor activated successfully";
          toast.success(message, { autoClose: 2000 });
        })
        .catch((error) => {
          setError(error);
          toast.error("Error updating status", { autoClose: 2000 });
        })
        .finally(() => {
          handleCloseActivateModal();
        });
    }
  };
  


  const handleDeleteConfirm = () => {
    deleteData(`${API_URL}/deleteDoctorById`, selectedItem._id, setError)
      .then(() => {
        setData((prevData) =>
          prevData.filter((item) => item._id !== selectedItem._id)
        );
        setFilteredData((prevFilteredData) =>
          prevFilteredData.filter((item) => item._id !== selectedItem._id)
        ); // Update filtered data

        toast.success("Deleted successfully", { autoClose: 2000 });
      })
      .catch((error) => {
        setError(error);
        toast.error("Error deleting data", { autoClose: 2000 });
      });
    handleCloseDeleteModal();
  };

  const totalPages = Math.ceil(filteredData.length / ITEMS_PER_PAGE);
  const currentData = getCurrentData(filteredData, currentPage, ITEMS_PER_PAGE);

  const fieldsToShow = {
    "First Name": "firstName",
    "Last Name": "lastName",
    "Provider Type": "providerType",
    Email: "email",
    Specialities: "specialities",
    State: "state",
    City: "city",
  };

  const states = [...new Set(data.map((therapist) => therapist.state))];
  const cities = [...new Set(data.map((therapist) => therapist.city))];
  const specialities = [
    ...new Set(data.map((therapist) => therapist.specialities)),
  ];

  const filteredCities =
    filters.State && filters.State.length > 0
      ? [
          ...new Set(
            data
              .filter((therapist) => filters.State.includes(therapist.state))
              .map((therapist) => therapist.city)
          ),
        ]
      : cities;

  const filterOptions = {
    State: states,
    City: filteredCities,
    Specialities: specialities,
  };

  const handleActions = (row) => {
    console.log("rowrowrow ", row);
    setSelectedRow(row)
    
    handleOpenActivateModal()
    
  }

  return (
    <div className="therapist-page">
      <div className=" flex justify-between items-center text-[#1B1F2E] px-0 py-4 mb-2">
        <h2 className="text-[28px] font-semibold">Therapist List</h2>
        <Link
          to="/therapist/add"
          className="font-normal text-white  py-2 px-4 rounded-md shadow hover:bg-gray-700 transition duration-200 bg-gray-800"
        >
          Add Therapist
        </Link>
      </div>
      <div className="search-filter-container shadow-sm  justify-between  gap-5 mb-3 flex-wrap bg-white rounded-lg px-3 py-3 flex items-start min-h-[170px] h-[170px]">
        <Search
          searchFields={["First Name", "Last Name", "Email"]}
          onSearch={handleSearch}
        />
        <Filter
          filterOptions={filterOptions}
          onFilterChange={handleFilterChange}
        />
      </div>

      <div className="table-container">
      <Table
  columns={columns}
  data={currentData}
  loading={loading}
  showIndex={true}
  currentPage={currentPage}
  itemsPerPage={ITEMS_PER_PAGE}
  onDelete={handleOpenDeleteModal}
  onActivate={handleActions} // Pass the function here
  onUpdate={(item) => navigate(`/therapist/edit/${item._id}`)}
  onView={handleOpenViewModal}
  showEdit={true}
  showForm={false}
  showActivate={true}
/>

        {!loading && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange(setCurrentPage)}
          />
        )}
      </div>
      <DeleteConfirmationModal
        show={showDeleteModal}
        onHide={handleCloseDeleteModal}
        onConfirm={handleDeleteConfirm}
      />
      <ActivateDoctorConfirmationModal
        show={showActivateModal}
        onHide={handleCloseActivateModal}
        onConfirm={handleActivateConfirm}
        selectedRow={selectedRow}
      />
      <ViewModal
        show={showViewModal}
        onHide={handleCloseViewModal}
        data={selectedItem}
        fields={fieldsToShow}
      />
     

    
    </div>
  );
};

export default Therapist;
