// import React, { useState } from "react";

// const Filter = ({ filterOptions, onFilterChange }) => {
//   const [selectedFilters, setSelectedFilters] = useState({});

//   const handleCheckboxChange = (filterKey, option) => {
//     const currentSelected = selectedFilters[filterKey] || [];
//     let updatedSelected;

//     if (currentSelected.includes(option)) {
//       updatedSelected = currentSelected.filter((item) => item !== option);
//     } else {
//       updatedSelected = [...currentSelected, option];
//     }

//     const updatedFilters = {
//       ...selectedFilters,
//       [filterKey]: updatedSelected,
//     };

//     setSelectedFilters(updatedFilters);
//     onFilterChange(updatedFilters);
//   };

//   const clearAllFilters = () => {
//     setSelectedFilters({});
//     onFilterChange({});
//   };
//   console.log(filterOptions,'sdsdsdsdsd')

//   const handleRemoveFilter = (filterKey, value) => {
//     const updatedSelected = selectedFilters[filterKey].filter(
//       (item) => item !== value
//     );

//     const updatedFilters = {
//       ...selectedFilters,
//       [filterKey]: updatedSelected,
//     };

//     if (updatedSelected.length === 0) {
//       delete updatedFilters[filterKey]; // remove the key if no filters are selected
//     }

//     setSelectedFilters(updatedFilters);
//     onFilterChange(updatedFilters);
//   };

  

//   return (
//     <div className="filter-component flex items-start gap-x-4">
//       {Object.keys(filterOptions).map((filterKey) => (
//         <div key={filterKey} className="filter-item mb-0 relative">
//           <div className="filter-control">
//             <div className="dropdown">
//               <button
//                 className="font-normal text-white py-2 px-4 rounded-md shadow hover:bg-gray-700 transition duration-200 bg-gray-800 dropdown-toggle"
//                 type="button"
//                 data-bs-toggle="dropdown"
//               >
//                 Select {filterKey}
//               </button>
//               <div className="dropdown-menu p-3">
//                 {filterOptions[filterKey].map((option) => (
//                   <div key={option} className="form-check">
//                     <input
//                       className="form-check-input"
//                       type="checkbox"
//                       value={option}
//                       checked={
//                         selectedFilters[filterKey]?.includes(option) || false
//                       }
//                       onChange={() => handleCheckboxChange(filterKey, option)}
//                     />
//                     <label className="form-check-label">{option}</label>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>

//           {selectedFilters[filterKey] &&
//             selectedFilters[filterKey].length > 0 && (
//               <div className="selected-values mt-2 flex flex-col px-2 py-2 shadow-sm w-full bg-[#ccc] max-h-[80px] overflow-y-auto custom-scrollbar min-h-[80px]">
//                 {selectedFilters[filterKey].map((value) => (
//                   <span
//                     key={value}
//                     className="me-2 text-sm font-normal flex items-center"
//                   >
//                     {value}
//                     <button
//                       onClick={() => handleRemoveFilter(filterKey, value)}
//                       className="ml-2 text-red-600 hover:text-red-800 font-bold"
//                     >
//                       ×
//                     </button>
//                   </span>
//                 ))}
//               </div>
//             )}
//         </div>
//       ))}

//       <div>
//         <button
//           onClick={clearAllFilters}
//           className="font-normal text-white py-2 px-4 rounded-md shadow hover:bg-gray-700 transition duration-200 bg-gray-800"
//         >
//           Clear All
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Filter;

import React, { useState } from "react";

const Filter = ({ filterOptions, onFilterChange }) => {
  const [selectedFilters, setSelectedFilters] = useState({});

  // Filter out empty arrays or null values from filterOptions
  const cleanFilterOptions = {};
  Object.keys(filterOptions || {}).forEach((key) => {
    cleanFilterOptions[key] = filterOptions[key]?.filter(
      (item) => Array.isArray(item) ? item.length > 0 : !!item
    );
  });
  console.log(cleanFilterOptions,'cleanFilterOptions')

  const handleCheckboxChange = (filterKey, option) => {
    const currentSelected = selectedFilters[filterKey] || [];
    let updatedSelected;

    if (currentSelected.includes(option)) {
      updatedSelected = currentSelected.filter((item) => item !== option);
    } else {
      updatedSelected = [...currentSelected, option];
    }

    const updatedFilters = {
      ...selectedFilters,
      [filterKey]: updatedSelected,
    };

    setSelectedFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  const clearAllFilters = () => {
    setSelectedFilters({});
    onFilterChange({});
  };

  const handleRemoveFilter = (filterKey, value) => {
    const updatedSelected = selectedFilters[filterKey].filter(
      (item) => item !== value
    );

    const updatedFilters = {
      ...selectedFilters,
      [filterKey]: updatedSelected,
    };

    if (updatedSelected.length === 0) {
      delete updatedFilters[filterKey]; // Remove the key if no filters are selected
    }

    setSelectedFilters(updatedFilters);
    onFilterChange(updatedFilters);
  };

  return (
    <div className="filter-component flex items-start gap-x-4">
      {Object.keys(cleanFilterOptions).map((filterKey) => (
        <div key={filterKey} className="filter-item mb-0 relative">
          <div className="filter-control">
            <div className="dropdown">
              <button
                className="font-normal text-white py-2 px-4 rounded-md shadow hover:bg-gray-700 transition duration-200 bg-gray-800 dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
              >
                Select {filterKey}
              </button>
              <div className="dropdown-menu p-3 max-h-[300px] overflow-y-auto">
                {cleanFilterOptions[filterKey].map((option) => (
                  <div key={option} className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={option}
                      checked={
                        selectedFilters[filterKey]?.includes(option) || false
                      }
                      onChange={() => handleCheckboxChange(filterKey, option)}
                    />
                    <label className="form-check-label">{option}</label>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {selectedFilters[filterKey] &&
            selectedFilters[filterKey].length > 0 && (
              <div className="selected-values mt-2 flex flex-col px-2 py-2 shadow-sm w-full bg-[#ccc] max-h-[80px] overflow-y-auto custom-scrollbar min-h-[80px]">
                {selectedFilters[filterKey].map((value) => (
                  <span
                    key={value}
                    className="me-2 text-sm font-normal flex items-center"
                  >
                    {value}
                    <button
                      onClick={() => handleRemoveFilter(filterKey, value)}
                      className="ml-2 text-red-600 hover:text-red-800 font-bold"
                    >
                      ×
                    </button>
                  </span>
                ))}
              </div>
            )}
        </div>
      ))}

      <div>
        <button
          onClick={clearAllFilters}
          className="font-normal text-white py-2 px-4 rounded-md shadow hover:bg-gray-700 transition duration-200 bg-gray-800"
        >
          Clear All
        </button>
      </div>
    </div>
  );
};

export default Filter;
