// src/hooks/useModal.js
import { useState } from 'react';

const useModal = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showActivateModal, setShowActivateModal] = useState(false);

  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpenDeleteModal = (item) => {
    setSelectedItem(item);
    setShowDeleteModal(true);
  };

  const handleOpenActivateModal = (item) => {
    setSelectedItem(item);
    setShowActivateModal(true);
  };

  const handleCloseDeleteModal = () => {
    setSelectedItem(null);
    setShowDeleteModal(false);
  };
  const handleCloseActivateModal = () => {
    setSelectedItem(null);
    setShowActivateModal(false);
  };

  const handleOpenViewModal = (item) => {
    setSelectedItem(item);
    setShowViewModal(true);
  };

  const handleCloseViewModal = () => {
    setSelectedItem(null);
    setShowViewModal(false);
  };

  return {
    showDeleteModal,
    showActivateModal,
    showViewModal,
    selectedItem,
    handleOpenDeleteModal,
    handleOpenActivateModal,
    handleCloseDeleteModal,
    handleCloseActivateModal,
    handleOpenViewModal,
    handleCloseViewModal,
  };
};

export default useModal;
