

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

import './BlogListing.css';

const API_URL = process.env.REACT_APP_API_URL;

const BlogWebView = () => {
  const [blogs, setBlogs] = useState([]);

    const navigate = useNavigate();
  
  useEffect(() => {
    // Fetching blogs dynamically (example API or local JSON)
    const fetchBlogs = async () => {
      const response = await fetch( `${API_URL}/getAllBlogs`); // Replace with your API endpoint
      const data = await response.json();
      console.log(data,'dyusyu')
      if(data?.data?.length == 1){

        navigate(`/blog/${data?.data[0]?._id}`)
      }
      setBlogs(data?.data);
    };

    fetchBlogs();
  }, []);

  return (
    <div className="blog-listing">
      <h1>Our Blogs</h1>
      <div className="blog-container">
        {blogs.length > 0 ? (
          blogs.map((blog) => (
            <div className="blog-card" key={blog.id}>
              <img src={blog.image} alt={blog.title} className="blog-image" />
              <div className="blog-content">
                <h2>{blog.title}</h2>
                <p>{blog.description.substring(0, 100)}...</p>
                <Link to={`/blog/${blog?._id}`} className="read-more">Read More</Link>
                </div>
            </div>
          ))
        ) : (
          <p>Loading blogs...</p>
        )}
      </div>
    </div>
  );
};

export default BlogWebView;
