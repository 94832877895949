// src/utils/utils.js
import axios from 'axios';





export const getData = async (url, setData, setError, processData, setFilteredData, setLoading) => {
  try {
    const response = await axios.get(url);
    const processedData = processData ? processData(response.data.data) : response.data.data;
    setData(processedData);
    setFilteredData(processedData)
    setLoading(false)
  } catch (error) {
    setError(error);
  }
};


export const fetchData = async (url, setData, setError, setFilteredData, setLoading) => {
  try {
    const response = await axios.get(url);
    const processedData = response.data.data; // Assuming the data is in response.data.data
    setData(processedData);
    setFilteredData(processedData); // Set filteredData to the same initial data
    setLoading(false)
  } catch (error) {
    setError(error);
  }
};


export const deleteData = async (url, id, setError) => {
  try {
    await axios.delete(`${url}/${id}`);
    // Refetch the data or update the state after deletion
  } catch (error) {
    setError(error);
  }
};




export const handlePageChange = (setCurrentPage) => (page) => {
  setCurrentPage(page);
};

export const getCurrentData = (data, currentPage, itemsPerPage) => {
  return data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
};
