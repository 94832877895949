// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BlogListing.css */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .blog-listing {
    padding: 20px;
  }
  
  .blog-listing h1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .blog-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .blog-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  
  .blog-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .blog-content {
    padding: 15px;
  }
  
  .blog-content h2 {
    font-size: 18px;
    margin: 0 0 10px;
    color: #555;
  }
  
  .blog-content p {
    font-size: 14px;
    color: #666;
    margin: 0 0 10px;
  }
  
  .read-more {
    color: #007bff;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
  }
  
  .read-more:hover {
    text-decoration: underline;
  }
  
  @media (min-width: 768px) {
    .blog-card {
      width: calc(33% - 20px);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/blogs/BlogListing.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,8BAA8B;IAC9B,SAAS;IACT,UAAU;EACZ;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,aAAa;IACb,eAAe;IACf,SAAS;IACT,uBAAuB;EACzB;;EAEA;IACE,gBAAgB;IAChB,sBAAsB;IACtB,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,wCAAwC;IACxC,gBAAgB;IAChB,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,WAAW;IACX,aAAa;IACb,iBAAiB;EACnB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,eAAe;IACf,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,eAAe;IACf,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,qBAAqB;IACrB,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE;MACE,uBAAuB;IACzB;EACF","sourcesContent":["/* BlogListing.css */\nbody {\n    font-family: Arial, sans-serif;\n    margin: 0;\n    padding: 0;\n  }\n  \n  .blog-listing {\n    padding: 20px;\n  }\n  \n  .blog-listing h1 {\n    text-align: center;\n    margin-bottom: 20px;\n    font-size: 24px;\n    color: #333;\n  }\n  \n  .blog-container {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 20px;\n    justify-content: center;\n  }\n  \n  .blog-card {\n    background: #fff;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    width: 90%;\n    max-width: 400px;\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .blog-image {\n    width: 100%;\n    height: 200px;\n    object-fit: cover;\n  }\n  \n  .blog-content {\n    padding: 15px;\n  }\n  \n  .blog-content h2 {\n    font-size: 18px;\n    margin: 0 0 10px;\n    color: #555;\n  }\n  \n  .blog-content p {\n    font-size: 14px;\n    color: #666;\n    margin: 0 0 10px;\n  }\n  \n  .read-more {\n    color: #007bff;\n    text-decoration: none;\n    font-size: 14px;\n    font-weight: bold;\n  }\n  \n  .read-more:hover {\n    text-decoration: underline;\n  }\n  \n  @media (min-width: 768px) {\n    .blog-card {\n      width: calc(33% - 20px);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
