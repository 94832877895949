import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const BlogDetails = () => {
  const { id } = useParams();  // Getting the blog ID from the URL
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await fetch(`https://therapist.api.ithemes.xyz/getBlogById/${id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setBlog(data);  // Assuming the response is in JSON format
        setLoading(false);
      } catch (error) {
        setError('Error fetching blog details');
        setLoading(false);
      }
    };

    fetchBlogDetails();
  }, [id]);

  if (loading) return <div className="text-center text-xl font-medium text-gray-700">Loading...</div>;
  if (error) return <div className="text-center text-xl font-medium text-red-500">{error}</div>;

  return (
    <div className="container mx-auto p-6 lg:px-16">
      {blog ? (
        <div>
          <h1 className="text-lg font-bold text-gray-900 mb-6">{blog?.data?.title}</h1>
          
          {/* Image section */}
          {blog?.data?.image && (
            <img
              src={blog?.data?.image}
              alt={blog?.data?.title}
              className="my-4 w-full max-h-[340px] object-cover rounded-lg shadow-md"
            />
          )}

          {/* Video section */}
          {blog?.data?.video && (
            <video
              controls
              src={blog?.data?.video}
              className="my-4 w-full h-[350px] object-cover rounded-lg shadow-lg"
            />
          )}

          {/* Blog Content */}
          <div className="prose max-w-full text-gray-700 mt-6">
                    <div
            className="text-lg mb-4"
            dangerouslySetInnerHTML={{ __html: blog?.data.content }}
            ></div>

            <div className="bg-gray-100 p-4 rounded-md mt-6">
              <h3 className="text-xl font-semibold text-gray-800">Meta Information</h3>
              <p className="text-sm text-gray-600 mt-2"><strong>Description:</strong> {blog?.data?.meta.description}</p>
              <p className="text-sm text-gray-600 mt-2"><strong>Keywords:</strong> {blog?.data?.meta.keywords}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="text-center text-xl text-gray-700">Blog not found</div>
      )}
    </div>
  );
};

export default BlogDetails;
